<template>
    <v-card :style="[{ borderTop: `4px solid ${mainColor}` }]" style="max-width: 240px; width: 220px" class="pa-2">
        <v-card-title class="pa-0 pb-2">
            <div class="d-flex justify-space-between align-center" style="width: 100%;">
                <h4 class="main--text">{{ translations.changeForemanCategory }}</h4>

                <v-btn @click="$emit('close')" fab width="18px" height="18px" color="main" depressed>
                    <v-icon small color="white">{{ icons.mdiClose }}</v-icon>
                </v-btn>
            </div>
        </v-card-title>

        <v-divider :style="[{ color: mainColor }, { height: '4px' }]"></v-divider>

        <v-card-text class="pa-0">
            <div class="input-group">
                <v-select v-model="selectedOption" :items="selectOptions" :label="translations.selectCategory" @change="categoryChange"></v-select>
            </div>

            <div class="input-group">
                <v-text-field v-model="inputValue" v-show="selectedOption" :label="translations.sortField" @change="sortChange"></v-text-field>
            </div>
        </v-card-text>
    </v-card>
</template>


<script>
import { translations } from '@/utils/common'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },

    categoryList: {
      type: Array,
      required: true
    },

    currentCategory: {
      type: String,
      required: false
    },

    currentSort: {
      type: String,
      required: false
    }
  },

  data: () => ({
    inputValue: '',
    selectedOption: null,
    selectOptions: []
  }),

  computed: {
    translations: () => translations,

    mainColor() {
      return this.$vuetify.theme.themes.light.main
    },

    icons: () => ({ mdiClose })
  },

  created() {
    // set the current sort filed
    this.inputValue = this.currentSort

    // map categories to select options
    this.selectOptions = this.categoryList.map(c => {
      return {
        text: c.name,
        value: c.code
      }
    })

    // add the empty category
    this.selectOptions = [{ text: '', value: null }, ...this.selectOptions]

    // set the current category
    this.selectedOption = this.currentCategory
  },

  watch: {
    currentSort(newValue) {
      this.inputValue = newValue
    },

    currentCategory(newValue) {
      this.selectedOption = newValue
    },

    show(newVal) {
      if (newVal === false) {
        this.selectedOption = this.currentCategory
        this.inputValue = this.currentSort
      }
    }
  },

  methods: {
    categoryChange() {
      this.$emit('category-change', this.selectedOption)
      if (this.selectedOption === null) {
        this.inputValue = null
        this.sortChange()
      }
    },

    sortChange() {
      this.$emit('sort-change', this.inputValue)
    }
  }
}
</script>


<style>
.input-group {
    display: flex;
    flex-direction: column;
}
</style>
